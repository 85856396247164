import simpleParallax from 'simple-parallax-js';

window.onload = () => {
  const backgrounds = document.querySelectorAll('article .background img');

  if (backgrounds.length) {
    new simpleParallax(backgrounds, {
      delay: 0,
      orientation: 'down',
      scale: 2,
      overflow: true,
    });
  }
};
